import { defaultTheme } from "react-admin";

export const theme = {
  ...defaultTheme,
  palette: {
    primary: {
      main: process.env.REACT_APP_ID == 'alixmed' ? "#6364AA" : "#227CB8",
    },
    secondary: {
      light: "#5f5fc4",
      main: "#F96F61",
      dark: "#001064",
      contrastText: "#fff",
    },
    background: {
      default: "#fcfcfe",
    },
    mode: "light" as "light",
  },
  typography: {
    fontFamily: '"Kollektif", sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '2.2rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '1.8rem',
      fontWeight: 600,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 600,
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 500,
    },
  },
  shape: {
    borderRadius: 10,
  },
  sidebar: {
    width: 250,
  },
  components: {
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          borderLeft: "3px solid #fff",
          "&.RaMenuItemLink-active": {
            borderLeft: "3px solid #4f3cc9",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: "none",
        },
        root: {
          border: "1px solid #e0e0e3",
          backgroundClip: "padding-box",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
        },
      },
    },
    RaLayout: {
      styleOverrides: {
        root: {
          "& .RaLayout-appFrame": {
            //marginTop: 48,
          },
          "& .RaLayout-content": {
            //marginTop: 40,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        // disable ripple for perf reasons
        disableRipple: true,
        disableElevation: true,
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          color: "#FFF",
          backgroundColor: process.env.REACT_APP_ID == 'alixmed' ? "#6364AA" : "#227CB8",
          border: 0,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: "#f5f5f5",
        },
        barColorPrimary: {
          backgroundColor: "#d7d7d7",
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0, 0, 0, 0.04)",
          "&$disabled": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          },
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          border: "none",
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          disabled: {
            color: "inherit",
          },
        },
      },
    },
  },
};
