const config = {
  alix: {
    resources: {
      exercises: 'Exercises',
      programs: 'AlixPrograms',
      inviteCodes: 'AlixInviteCodes',
      users: 'Users',
    },
    queries: {
      listExercises: 'listExercises',
      listExercisesByName: 'listExercisesByName',
      listExercisesByIntensity: 'listExercisesByIntensity',
      listInviteCodes: 'listAlixInviteCodes',
      listInviteCodesByRefKey: 'listAlixInviteCodesByRefKey',
      listInviteCodesByName: 'listAlixInviteCodesByName',
    },
    apiName: 'alixRestApi',
  },
  alixmed: {
    resources: {
      exercises: 'AlixMedExercises',
      programs: 'AlixMedPrograms',
      inviteCodes: 'AlixMedInviteCodes',
      users: 'AlixMedUsers',
    },
    queries: {
      listExercises: 'listAlixMedExercises',
      listExercisesByName: 'listAlixMedExercisesByName',
      listExercisesByIntensity: 'listAlixMedExercisesByIntensity',
      listInviteCodes: 'listAlixMedInviteCodes',
      listInviteCodesByRefKey: 'listAlixMedInviteCodesByRefKey',
      listInviteCodesByName: 'listAlixMedInviteCodesByName',
    },
    apiName: 'alixMedRestApi',
  }
};

export default config;